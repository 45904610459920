import React from 'react'
import { injectIntl, Link, FormattedMessage } from 'gatsby-plugin-intl'
import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = ({ intl }) => {
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'notFound.header' })} />
      <div
        style={{
          maxWidth: `800px`,
          margin: `0 auto`,
        }}
      >
        <h1>{intl.formatMessage({ id: 'notFound.header' })}</h1>
        <p>{intl.formatMessage({ id: 'notFound.description' })}</p>
        <Link to='/'>
          <FormattedMessage id='homePage' />
        </Link>
      </div>
    </Layout>
  )
}
export default injectIntl(NotFoundPage)
